import React from 'react';
import { Link } from "gatsby"

import './styles.scss';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Button } from 'react-bootstrap';

const CardBox = ({isHow, img, title, description}) => {
    return (
        <>
            <Card className={isHow ? "card-shadow" : "h-100"} style={{ width: '18rem', border:isHow ? "none" : null }}>
                <Card.Img className={isHow ? "mx-auto" : null} style={{ width: isHow ? '10rem' : null}} variant="top" src={img} />
                <Card.Body>
                    <Card.Title className="title-card">{title}</Card.Title>
                    <Card.Text className="paragraph-card">
                        {description}
                    </Card.Text>
                </Card.Body>
            </Card>
        </>
    )
}

export default CardBox;